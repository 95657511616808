import React from "react";
import { Navigate, Route, RouteProps } from "react-router-dom";

// components
import PrivateRoute from "./PrivateRoute";
import Bulkupload from "../pages/messageLibrary/Bulkupload";
// import Root from "./Root";

// lazy load all the views

// auth
const Login = React.lazy(() => import("../pages/auth/Login"));
const Logout = React.lazy(() => import("../pages/auth/Logout"));
const Confirm = React.lazy(() => import("../pages/auth/Confirm"));
const ForgetPassword = React.lazy(() => import("../pages/auth/ForgetPassword"));
const Register = React.lazy(() => import("../pages/auth/Register"));
const LockScreen = React.lazy(() => import("../pages/auth/LockScreen"));

// landing
const Landing = React.lazy(() => import("../pages/landing/"));

// apps
const CalendarApp = React.lazy(() => import("../pages/apps/Calendar/"));
const Moods = React.lazy(() => import("../pages/messageLibrary/Moods"));
const Messages = React.lazy(() => import("../pages/messageLibrary/Messages"));
const Plans = React.lazy(() => import("../pages/plans/Plans"));
const Couples = React.lazy(() => import("../pages/subscription/Subscription"));
const Subscription = React.lazy(() => import("../pages/subscription/Subscription"));
const Transaction = React.lazy(() => import("../pages/transaction/Transaction"));
const Addons = React.lazy(() => import("../pages/addon/Addon"));
const Users = React.lazy(() => import("../pages/users/Users"));
const Cron = React.lazy(() => import("../pages/cronJob/Cron"));

// extra pages
const Error404 = React.lazy(() => import("../pages/error/Error404"));
const Error500 = React.lazy(() => import("../pages/error/Error500"));
// -other
const Maintenance = React.lazy(() => import("../pages/other/Maintenance"));
const PrivacyPolicy = React.lazy(() => import("../pages/PrivacyPolicy/PrivacyPolicy"));
const Eula = React.lazy(() => import("../pages/eula/Eula"));
const ContactUs = React.lazy(() => import("../pages/contactus/contactus"));

export interface RoutesProps {
  path: RouteProps["path"];
  name?: string;
  element?: RouteProps["element"];
  route?: any;
  exact?: boolean;
  icon?: string;
  header?: string;
  roles?: string[];
  children?: RoutesProps[];
}

// dashboards
const dashboardRoutes: RoutesProps = {
  path: "/dashboard",
  name: "Dashboards",
  icon: "airplay",
  route: PrivateRoute,
  header: "Navigation",
  children: [
    {
      path: "/",
      name: "Root",
      element: <Navigate to="/dashboard" />,
      route: PrivateRoute,
    },
  ],
};

const calendarAppRoutes: RoutesProps = {
  path: "/apps/calendar",
  name: "Calendar",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "calendar",
  element: <CalendarApp />,
  header: "Apps",
};

// pages
const messageRoutes: RoutesProps = {
  path: "/message-library",
  name: "Library",
  icon: "package",
  header: "Custom",
  children: [
    {
      path: "/moods",
      name: "Moods",
      element: <Moods />,
      route: PrivateRoute,
    },
    {
      path: "/messages",
      name: "Messages",
      element: <Messages />,
      route: PrivateRoute,
    },
    {
      path: "/bulk-upload",
      name: "Bulupload",
      element: <Bulkupload />,
      route: PrivateRoute,
    },
  ],
};

// plans
const plansRoutes: RoutesProps = {
  path: "/plans",
  name: "Plans",
  route: PrivateRoute,
  icon: "calendar",
  element: <Plans />,
  header: "Apps",
};

// Couples
const couplesRoutes: RoutesProps = {
  path: "/couples",
  name: "Couples",
  route: PrivateRoute,
  icon: "calendar",
  element: <Couples />,
  header: "Apps",
};

// Subscription
const subscriptionRoutes: RoutesProps = {
  path: "/subscriptions",
  name: "Subscriptions",
  route: PrivateRoute,
  icon: "calendar",
  element: <Subscription />,
  header: "Apps",
};

// Transaction
const transactionRoutes: RoutesProps = {
  path: "/transactions",
  name: "Transactions",
  route: PrivateRoute,
  icon: "calendar",
  element: <Transaction />,
  header: "Apps",
};

// Addons
const addonRoutes: RoutesProps = {
  path: "/addons",
  name: "Addons",
  route: PrivateRoute,
  icon: "calendar",
  element: <Addons />,
  header: "Apps",
};

// users
const userRoutes: RoutesProps = {
  path: "/users",
  name: "Users",
  route: PrivateRoute,
  icon: "user",
  element: <Users />,
  header: "Apps",
};

// cron
const cronRoute: RoutesProps = {
  path: "/cron-jobs",
  name: "Cron",
  route: PrivateRoute,
  icon: "user",
  element: <Cron />,
  header: "Apps",
};

// auth
const authRoutes: RoutesProps[] = [
  {
    path: "/auth/login",
    name: "Login",
    element: <Login />,
    route: Route,
  },
  {
    path: "/auth/register",
    name: "Register",
    element: <Register />,
    route: Route,
  },
  {
    path: "/auth/confirm",
    name: "Confirm",
    element: <Confirm />,
    route: Route,
  },
  {
    path: "/auth/forget-password",
    name: "Forget Password",
    element: <ForgetPassword />,
    route: Route,
  },
  {
    path: "/auth/lock-screen",
    name: "Lock Screen",
    element: <LockScreen />,
    route: Route,
  },
  {
    path: "/auth/logout",
    name: "Logout",
    element: <Logout />,
    route: Route,
  },
];

// public routes
const otherPublicRoutes: RoutesProps[] = [
  {
    path: "/landing",
    name: "landing",
    element: <Landing />,
    route: Route,
  },
  {
    path: "/maintenance",
    name: "Maintenance",
    element: <Maintenance />,
    route: Route,
  },
  {
    path: "/error-404",
    name: "Error - 404",
    element: <Error404 />,
    route: Route,
  },
  {
    path: "/error-500",
    name: "Error - 500",
    element: <Error500 />,
    route: Route,
  },
  {
    path: "/privacy-policy",
    name: "privacy - Policy",
    element: <PrivacyPolicy />,
    route: Route,
  },
  {
    path: "/eula",
    name: "Eula",
    element: <Eula />,
    route: Route,
  },
  {
    path: "/contactus",
    name: "Contact Us",
    element: <ContactUs />,
    route: Route,
  },
];

// flatten the list of all nested routes
const flattenRoutes = (routes: RoutesProps[]) => {
  let flatRoutes: RoutesProps[] = [];

  routes = routes || [];
  routes.forEach((item: RoutesProps) => {
    flatRoutes.push(item);

    if (typeof item.children !== "undefined") {
      flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
    }
  });
  return flatRoutes;
};

// All routes
const authProtectedRoutes = [
  dashboardRoutes,
  calendarAppRoutes,
  messageRoutes,
  plansRoutes,
  couplesRoutes,
  subscriptionRoutes,
  transactionRoutes,
  addonRoutes,
  userRoutes,
  cronRoute,
];
const publicRoutes = [...authRoutes, ...otherPublicRoutes];

const authProtectedFlattenRoutes = flattenRoutes([...authProtectedRoutes]);
const publicProtectedFlattenRoutes = flattenRoutes([...publicRoutes]);
export { publicRoutes, authProtectedRoutes, authProtectedFlattenRoutes, publicProtectedFlattenRoutes };
